// import burgerImg from "../assets/burger.png";
// import mapImg from "../assets/map.png";
// import popcornImg from "../assets/popcorn.png";
// import pokemonImg from "../assets/pokemon.png";

export default function Projects() {
  return (
    <div id="projects">
      <div>
        <h2>Projects</h2>
        <div className="projects">
          <div className="project-hamburger">
            {/* <img src={burgerImg} alt="burger" /> */}
            <h3 className="project-name">Burger Builder</h3>
            <br />
            <a
              href="https://evelinn-burger-builder.netlify.app/"
              target="_blank"
              rel="noreferrer"
              className="project-description"
            >
              Built using React
            </a>
          </div>
          <div className="project-converter">
            {/* <img src={mapImg} alt="burger" /> */}
            <h3 className="project-name">
              Country Currency
              <br />
              Converter
            </h3>
            <a
              href="https://github.com/evelinnidenfors/country-search-currency-converter"
              target="_blank"
              rel="noreferrer"
              className="project-description"
            >
              Built using React
            </a>
          </div>
          <div className="project-app">
            {/* <img src={popcornImg} alt="burger" /> */}
            <div>
              <h3 className="project-name">Text Input Converter</h3>
              <br />
              <a
                href="https://convert-input.vercel.app/"
                target="_blank"
                rel="noreferrer"
                className="project-description"
              >
                Converting a text input and reformats the output. Built to save
                time at work and quickly reformat 1000s of rows of data in one
                go. <br />
                <br />
                Built using NextJS.
              </a>
            </div>
          </div>
          <div className="project-pokemon">
            {/* <img src={pokemonImg} alt="burger" /> */}
            <h3 className="project-name">Photography Page</h3>
            <br />
            <a
              href="https://evelinns.photos/"
              target="_blank"
              rel="noreferrer"
              className="project-description"
            >
              Using Unsplash API to showcase photos I've taken on various trips{" "}
              <br />
              <br />
              Built using NextJS.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
