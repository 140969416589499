import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";
import Navigation from "./navbar";
import AboutMe from "./pages/about-me";
import Experience from "./pages/experience";
import Projects from "./pages/projects";
import Footer from "./pages/footer";
import "./App.css";

function App() {
  const TRACKING_ID = "G-X6BPFDD03D";

  ReactGA.initialize(TRACKING_ID);
  ReactGA.send("pageview");

  const [backgroundHeader, setBackgroundHeader] = useState("");

  const setBackgroundImage = () => {
    const time = new Date().getHours();
    const H1_ELEMENT = document.getElementById("header");

    if (time >= 6 && time < 14) {
      // setBackgroundHeader("/Lake-day.svg");
      setBackgroundHeader("/hero/london-morning.png");
    } else if (time >= 14 && time < 18) {
      // setBackgroundHeader("/Lake-sunset.svg");
      setBackgroundHeader("/hero/london-sunset.png");
    } else {
      // setBackgroundHeader("/Lake-night.svg");
      setBackgroundHeader("/hero/london-night.png");
      H1_ELEMENT.style.color = "#efd6bd";
    }
  };

  setInterval(() => {
    setBackgroundImage();
  }, 3600000);

  useEffect(() => setBackgroundImage(), []);

  return (
    <div className="App" id="home">
      <Navigation />
      <div className="header">
        <header
          className="App-header"
          style={{
            backgroundImage: `url(${backgroundHeader})`,
            minHeight: "100vh",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></header>
        <h1 id="header">Evelinn</h1>
      </div>
      <AboutMe />
      <Experience />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
