import React from "react";
import ExperienceCard from "../components/experience-card";

export default function Experience() {
  return (
    <div id="experience">
      <ExperienceCard
        // key="1"
        image="/london-2.jpg"
        jobTitle="eCommerce Solutions Architect"
        company="CreatorIQ"
        city="London"
        country="United Kingdom"
        experienceItems={[
          "Onboarding customers to the platform and ensuring they are trained in the modules included in their contract",
          "Taking part in pre-sales activities to cover functionalities around API and Affiliates within the tool",
          "Setting up sites for customers where influencers can apply to take part in their influencer and/or affiliate program",
        ]}
        alignment="even"
      />
      <ExperienceCard
        // key="2"
        image="/stockholm-2.png"
        jobTitle="Technical Product Specialist"
        company="Bannerflow"
        city="Stockholm"
        country="Sweden"
        experienceItems={[
          "Developing custom solutions for customers if not achievable with features within the platform and creating documentation on how these solutions are working and how to use them. This is done using JavaScript, Vue.js and C# and integrating with various APIs",
          "Hold technical meetings with customers, covering everything from how to integrate their feed in our tool to how to use custom features available or developed",
          "Working with sales to participate in meetings with mature prospects looking for more complex setups",
          "Participate in testing sessions to QA new features in the platform prior to release. This to ensure any bugs could be solved before the planned release",
        ]}
        alignment="odd"
      />
      <ExperienceCard
        // key="3"
        image="/stockholm-1.png"
        jobTitle="Project Manager"
        company="Made People"
        city="Stockholm"
        country="Sweden"
        experienceItems={[
          "Managing the delivery of websites for ecommerce clients, delivered within set budget and timeframe",
          "Writing down technical requirements for each task and ensuring these were added to JIRA",
          "Holding sprint meetings and planning with designers, developers and QA the complexity and estimated delivery of each task",
          "Worked using JIRA, Centra, Storyblok and Findify",
        ]}
        alignment="even"
      />
      <ExperienceCard
        // key="4"
        image="/new-york.jpg"
        jobTitle="Lead Technical Account Manager"
        company="Yieldify"
        city="New York"
        country="USA"
        experienceItems={[
          "Built technical campaigns for clients globally, the campaigns were running on their websites and also through emails",
          "Used Photoshop for design changes prior to launching the campaign",
          "Worked in GitHub and with TypeScript for more technical and custom setups, this using HTML, CSS, Javascript and TypeScript",
          "Take part in test sessions for new features prior to realesing them in the platform and reporting any bugs that needed fixing before the release took place",
        ]}
        alignment="odd"
      />
      <ExperienceCard
        // key="5"
        image="/london-1.jpg"
        jobTitle="Event Coordinator"
        company="Shoreditch Works"
        city="London"
        country="United Kingdom"
        experienceItems={[
          "Planned events for tech companies in London",
          "Gathered requirements around venue setup and food to then arrange for all parts",
          "Managed the budget for the events",
          "Rebuilt website using Wordpress",
        ]}
        alignment="even"
      />
    </div>
  );
}
