import React from "react";

function AboutMe() {
  return (
    <div id="about-me">
      <div>
        <h2>Hello 👋🏼</h2>
        <p>
          I'm Evelinn, a self-taught front-end developer 👩🏼‍💻 who loves to learn
          new things.
        </p>
        <p>
          My background is as a Technical Account Manager with over 5 years of
          experience in the digital marketing industry. Skilled in project
          management, team leadership, and product development. Proficient in
          JIRA and Salesforce, and knowledgeable in HTML, CSS, and JavaScript.
          I'm a quick learner, multi-tasking, and meeting deadlines. Track
          record of delivering value to clients globally. When building apps and
          websites, or working on smaller side projects, I use HTML, CSS,
          JavaScript, TypeScript, React and React Native.
        </p>
      </div>
    </div>
  );
}

export default AboutMe;
