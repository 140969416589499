import React from "react";

export default function ExperienceCard(props) {
  return (
    <div
      className="experience"
      style={{
        display: "flex",
        flexDirection: props.alignment === "even" ? "row" : "row-reverse",
      }}
    >
      <div
        className="experience-img"
        style={{
          backgroundImage: `url(${props.image})`,
          minHeight: "100vh",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      <div className={`description-box ${props.alignment}`}>
        <div className="description">
          <h2>
            {props.jobTitle}
            <br />
            {props.company}
          </h2>
          <h3>
            {props.city} - {props.country}
          </h3>
          <ul>
            {props.experienceItems.map((item) => {
              return <li key={item.split(" ")[0]}>{item}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
